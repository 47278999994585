@import "colors";
@import "fonts";
@import "layouts";
@import "inputs";
@import "buttons";
@import "forms";
html,
body {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

iframe {
  display: none;
}
.version{
  position:fixed;
  bottom:0px;
  right:0px;
  color:black;
  opacity:0;
  font-size: 15px;
}
