.contain {
  max-width: 1000px;
  margin: auto;
}
.loading h1{
  
    animation: flasher 3s infinite;
    -webkit-animation: flasher 3s infinite;
  
}

@keyframes flasher {
  0%,
  40%,
  80% {
    transform: scale(1);
  }
  25%,
  65% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes flasher {
  0%,
  40%,
  80% {
    transform: scale(1);
  }
  25%,
  65% {
    transform: scale(0.9);
  }
}


section {
  padding-top: 100px;
}
.center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  &.small{
    margin-top: 0px;
  }

}

.col{
  &.col-1{
    width: 8.33%
  }
  &.col-2{
    width: 16.66%
  }
  &.col-3{
    width: 25%
  }
  &.col-4{
    width: 33.33%
  }
  &.col-5{
    width: 41.66%
  }
  &.col-6{
    width: 50%
  }
  &.col-7{
    width: 58.33%
  }
  &.col-8{
    width: 66.66%
  }
  &.col-9{
    width: 75%
  }
  &.col-10{
    width: 83.33%
  }
  &.col-11{
    width: 91.66%
  }
  &.col-12{
    width: 100%
  }
  &.right{
    justify-content: right;
    align-items: flex-start;
  }
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}




