.button{
    &.disabled{
        cursor:default;
    }
    cursor:pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &.link p{
        cursor:pointer;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: underline;
        color: var(--primaryColor); 
    }
    &.link.disabled p{
        pointer-events:none;
        color: var(--standardColor); 
    }
    
    &.large{
        width: 479px;
        height: 49px;
        background: var(--primaryColor);
        border-radius: 3px;
        &.disabled{
            background: var(--primaryLightColor);
        }
        }
    &.back{
        background:none;
        svg{
            fill: var(--primaryColor);
            width: 7px;
            height: 14px;
            margin: 2px 20px 0px 0px;
        }
        p{
            cursor:pointer;
            font-size: 16px;
            line-height: 24px;
            color: var(--primaryColor);
        }
    }
    p{
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        margin:0px;
        color: #FFFFFF;
    }
}