@import url("https://use.typekit.net/cbx4oxh.css");

* {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: var(--primaryColor);
}

p {
  color: var(--standardColor);
  &.italic{
    font-style: italic;
  }
  
}

a {
  color: inherit;
  text-decoration: none;
}
span.warn{
  color:var(--warningColor);
}

.titleSubtitle {
  text-align: center;
  width: 100%;
  &.left {
    text-align: left;
  }
  p {
    margin: 0;
    font-size: 20px;
  }
  h1 {
    font-size: 60px;
    margin: 0;
    margin-top: 5px;
  }
  h2 {
    font-size: 40px;
    margin: 0;
    margin-top: 5px;
  }
}
