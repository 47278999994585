header {
  position: fixed;
  top:0;
  right: 0;
  width: 100%;
  padding: 20px 50px;
  background: var(--primaryLighterColor);
  display: flex;
  flex-direction: row;
  .logo{
    height: 45px;
    width:auto;
    padding-right: 30px;
  }
  .partners{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    p{
      text-align: center;
      font-size: 12px;

      margin:0 0 5px 0;
    }
    .partner{
      height: 25px;
      width:auto;
    }
  }
}
