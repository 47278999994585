.input{
    width:100%;
    height:90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    label{
        font-size: 14px;
        line-height: 18px;
        color: var(--primaryColor);
        margin-bottom: 4px;
    }
    select,input[type=text], input[type=tel]{
        width:100%;
        height: 40px;
        padding: 8px 13px;
        font-size: 14px;
        line-height: 1.2;
        color: #767676;
        border: 1px solid #E3E7F1;
        box-sizing: border-box;
        border-radius: 3px;
        &::placeholder {
            opacity: 0.6;
          }
        &.inputError{
            border: 1px solid var(--warningColor);
        }

    }
    .error{
        font-size: 12px;
        line-height: 16px;
        color: var(--warningColor);
    }
}
.inputs {
    width: 100%;
    position: relative;
  }
.check{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    input[type="checkbox"]{
        -webkit-appearance: none;
        box-sizing: content-box;
        width: 16px;
        height: 15px;
        background: #FFFFFF;
        border: 1px solid #E3E7F1;
        border-radius: 2px;
        margin: 10px 0px;
        &:checked{
            background: var(--primaryColor);
        }
    }
    label{
        margin-left: 10px;
        font-size: 14px;
        line-height: 15px;
        color: var(--primaryColor);

    }
}
.select{
    margin-top:36px;
    
    width:100%;
    select{
        font-weight: 400;
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.01em;
        padding: 26px 0px 32px 63px;
        width:100%;
        height: 100px;
        background: #FFFFFF;
        border: 1px solid var(--primaryColor);
        margin-bottom:36px;
    }
}

.autocomplete-dropdown-container {
    background: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 20;
    margin-top: -10px;
    .suggestion-item,
    .suggestion-item--active {
      padding: 8px 15px;
      border-bottom: 1px solid #eaeaea;
    }
  }