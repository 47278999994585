.styleGuidePage {
  .guideItem {
    margin-bottom: 30px;
    .title {
      width: 100%;
      padding-bottom: 5px;
      border-bottom: 1px solid var(--primaryLightColor);
    }

    .colors {
      display: flex;
      flex-wrap: wrap;
      .color {
        margin-right: 20px;
        margin-bottom: 10px;
        .swatch {
          width: 150px;
          height: 120px;
        }
        .info {
          padding-top: 10px;

          p {
            font-size: 15px;
            margin: 0;
          }
        }
      }
    }

    .styleTypes {
      display: flex;
      flex-direction: column;
      .styleType {
        margin-bottom: 50px;
        display: flex;
        .name {
          opacity: 0.7;
          flex: none;
          width: 130px;
          margin: 0;
          margin-right: 30px;
        }
        .style {
          width: 100%;
          p {
            margin-top: 0;
          }
        }
      }
    }
  }
}
