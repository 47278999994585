.form{
    max-width: 600px;
    &.small{
        max-width: 480px;
    }
    &.large{
        padding-left: 100px;
        max-width: 750px;
    }
    @media screen and (max-width: 576px) {
            margin: 0px 10px 10px;
    }
    margin: 0px auto;
    flex-direction: column;
    justify-content:center;
    align-items: center;

    .body{
        margin: 40px 0;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content:center;
        align-items: center;
        row-gap:23px;
        
       
        .remove{
            display: flex;
            color: red;
            justify-content: left;
            text-decoration: underline;
            margin-bottom: 1rem;
            font-size:small;
            cursor: pointer;
            
        }
        .row{
            margin-top: 3px;
            column-gap: 40px;
            &.small{
                margin-top: 0px;
            }
        }
        hr{
            border: 1px solid #E3E7F1;}
    }
    .control{
        margin: 30px 0 30px;
        .row{
            margin-top:20px;
        }
    }
}

.progress{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom:40px;
    .tabs{
        margin-top: 45px;
        display: flex;
        align-items: center;
        flex-direction: row;
        @media screen and (max-width: 840px) {
            width: 90%;
        }
        width:800px;
        padding-bottom: 11px;
        .tab{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            width:200px;
            margin-right: 20px;
            @media screen and (max-width: 576px) {
                margin-right: 0px;
                flex-direction: column;
            }
            color:#3B54A4;
            font-style: normal;
            font-weight: normal;
            font-size: 14.4065px;
            line-height: 14px;
            .icon{
                @media screen and (max-width: 576px) {
                margin-right: 0px;
                margin-bottom: 5px;
                }
                height:30px;
                width:30px;
                background: #E3E7F1;
                border-radius:50%;
                display: flex;
                justify-content: center;
                align-items: center;
                
                margin-right: 16px;
                svg{
                    height:16px;
                    width:auto;
                    
                    path{
                        fill:#3B54A4;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                        -moz-transition: all 0.3s;
                        -ms-transition: all 0.3s;
                        -o-transition: all 0.3s;
                    }
                }
                &.active{
                    cursor:pointer;
                    background: #3B54A4;
                    svg{
                        filter:brightness(0) invert(1);
                    }
                }
            }
            &.active{
                color: #3B54A4;
                svg{
                    fill:#3B54A4;

                }

            }

        }
    }
    .bar{
    @media screen and (max-width: 840px) {
        width: 90%;
    }
        width: 800px;
        height:5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background: #E3E7F1;
        span{
            height:100%;
            font-family: Sofia Pro;


            background: #3B54A4;
            transition: all 0.3s;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
        }
        
    }
}
.formSubmitted{
    margin-top:90px;
    h1{
        margin: 22px 0px 40px 0px;
    }
    .button{
        margin-top: 47px;
    }
}